<template>
	<pui-datatable
		:customizeActionAvailability="customizeActionAvailability"
		:modelName="modelName"
		:parentModelName="parentModelName"
		:parentModel="parentModel"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:readOnly="readOnly"
		:showMasterDetailFilterListBtn="true"
		:showMasterDetailFilterBtn="true"
		:showMasterDetailSortBtn="true"
		:showMasterDetailConfBtn="true"
		:modelColumnDefs="modelColumnDefs"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import { STATUS_DRAFT_ID } from '../../constants/imofalforms/ImoFalFormsConstants';

export default {
	name: 'driverlist-grid',
	mixins: [PuiGridDetailMixin],

	data() {
		return {
			modelName: 'driverlist',
			modelColumnDefs: {
				extradriverind: {
					render: (data) => {
						if (data === 0) {
							return this.$t('grid.driverlist.no');
						} else if (data === 1) {
							return this.$t('grid.driverlist.yes');
						} else {
							return '';
						}
					}
				},
				extravehicleind: {
					render: (data) => {
						if (data === 0) {
							return this.$t('grid.driverlist.no');
						} else if (data === 1) {
							return this.$t('grid.driverlist.yes');
						} else {
							return '';
						}
					}
				},
				birthdate: {
					render: (data) => {
						return new Date(data).toLocaleDateString('es-CL');
					}
				},
				expirydate: {
					render: (data) => {
						return new Date(data).toLocaleDateString('es-CL');
					}
				}
			}
		};
	},
	methods: {
		customizeActionAvailability(actionId, parentModel) {
			if (actionId === 'puiImportAction' || actionId === 'puiExportAction') {
				return parentModel.statusid === STATUS_DRAFT_ID;
			}
		}
	}
};
</script>
